import { css } from '@emotion/react';
import { desktopFirstMediaQuery } from '@inflearn/ds-react';
import { motion, useMotionValueEvent, useScroll } from 'framer-motion';
import { useRef, useState } from 'react';
import { GATSBY_CDN_URL } from '../../../../../utils/env';
import useClientHeight from '../../../commons/hooks/useClientHeight';
import LineTransition from './LineTransition';
import VideoTransition from './VideoTransition';
import {
  INTRODUCE_DESCRIPTION_WIDTH_MB,
  INTRODUCE_DESCRIPTION_WIDTH_PC,
  INTRODUCE_DESCRIPTION_WIDTH_TB,
  INTRODUCE_TITLE_WIDTH_MB,
  INTRODUCE_TITLE_WIDTH_PC,
  INTRODUCE_TITLE_WIDTH_TB,
  INTRO_END,
  INTRO_START,
  VIDEO_START,
  VIDEO_TRANSITION_KEYFRAMES
} from './constants';
import {
  commonSectionItemVariants,
  commonSectionItemVariantsWithoutY,
  commonSectionVariants
} from './variants';

const VideoSection = () => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollIntroTargetRef = useRef<HTMLDivElement>(null);

  const descriptionRef = useRef<HTMLDivElement>(null);
  const { clientHeight, sectionVideoTop, updateClientHeight } = useClientHeight();
  const [scrollY, setScrollY] = useState<number>(0);
  const { scrollY: scrollYInView } = useScroll({ target: scrollContainerRef });

  useMotionValueEvent(scrollYInView, 'change', (top) => {
    setScrollY(top);

    if (scrollContainerRef?.current == null) {
      return;
    }
    if (scrollIntroTargetRef?.current == null) {
      return;
    }

    const introOpacityProgress = getProgress({
      start: INTRO_START(clientHeight, sectionVideoTop),
      end: INTRO_END(clientHeight, sectionVideoTop),
      current: top
    });

    if (top < VIDEO_START(clientHeight, sectionVideoTop)) {
      scrollIntroTargetRef.current.style.opacity = String(introOpacityProgress);
      updateClientHeight();
      return;
    }

    scrollIntroTargetRef.current.style.opacity = '0';
  });

  return (
    <motion.section
      id={'section-video'}
      layoutScroll
      ref={scrollContainerRef}
      initial="offscreen"
      whileInView="onscreen"
      variants={commonSectionVariants}
      css={styleVideoInteractionSection}>
      <div ref={scrollIntroTargetRef} css={styleIntroWrapper}>
        <motion.div
          animate={scrollY > INTRO_START(clientHeight, sectionVideoTop) ? 'onscreen' : 'offscreen'}
          css={styleIntroTitle}
          variants={commonSectionItemVariants()}>
          <img
            loading="lazy"
            alt="Learn Share Grow"
            src={`${GATSBY_CDN_URL}/infcon/infcon-2023/main-introduce-title-pc.svg`}
            css={{
              display: 'block',
              [desktopFirstMediaQuery.mobile]: {
                display: 'none'
              }
            }}
          />
          <img
            loading="lazy"
            alt="Learn Share Grow"
            src={`${GATSBY_CDN_URL}/infcon/infcon-2023/main-introduce-title-mb.svg`}
            css={{
              display: 'none',
              [desktopFirstMediaQuery.mobile]: {
                display: 'block'
              }
            }}
          />
        </motion.div>
        <motion.div
          animate={scrollY > INTRO_START(clientHeight, sectionVideoTop) ? 'onscreen' : 'offscreen'}
          css={styleIntroDescription}
          variants={commonSectionItemVariantsWithoutY({ delay: 0.2 })}
          ref={descriptionRef}>
          <motion.div
            css={{
              display: 'flex',
              flexFlow: 'row nowrap',
              columnGap: '16px',
              width: '100%',
              [desktopFirstMediaQuery.mobile]: {
                columnGap: '12px'
              }
            }}>
            <motion.div
              style={{
                flexGrow: 1,
                width: '100%',
                height: '100%'
              }}>
              <LineTransition scrollY={scrollY} descriptionRef={descriptionRef} />
            </motion.div>
            <motion.img
              alt="인프콘 2023으로 여러분을 초대합니다."
              src={`${GATSBY_CDN_URL}/infcon/infcon-2023/main-introduce-description-pc.svg`}
            />
            <motion.div
              css={{
                width: '100%'
              }}
            />
          </motion.div>
        </motion.div>
      </div>
      <VideoTransition scrollY={scrollY} />
    </motion.section>
  );
};

const styleVideoInteractionSection = css({
  position: 'relative',
  height: `${VIDEO_TRANSITION_KEYFRAMES.LENGTH * 100}vh`,
  background: 'linear-gradient(180deg, #f6fafe 0%, #ffffff 100%)'
});

const styleIntroWrapper = css({
  position: 'fixed',
  top: '64px',
  display: 'flex',
  flexFlow: 'column wrap',
  alignItems: 'center',
  justifyContent: 'center',

  width: '100%',
  height: 'calc(100vh - 64px)',
  opacity: 0,

  pointerEvents: 'none'
});

const styleIntroTitle = css({
  width: INTRODUCE_TITLE_WIDTH_PC,
  maxWidth: '1200px',
  marginBottom: '60px',
  [desktopFirstMediaQuery.tablet]: {
    width: INTRODUCE_TITLE_WIDTH_TB
  },
  [desktopFirstMediaQuery.mobile]: {
    width: INTRODUCE_TITLE_WIDTH_MB
  },
  img: {
    width: '100%'
  }
});

const styleIntroDescription = css({
  overflow: 'hidden',
  display: 'flex',
  flexFlow: 'column wrap',
  alignItems: 'center',
  justifyContent: 'center',

  width: INTRODUCE_TITLE_WIDTH_PC,
  maxWidth: '1200px',

  [desktopFirstMediaQuery.tablet]: {
    width: INTRODUCE_TITLE_WIDTH_TB
  },
  [desktopFirstMediaQuery.mobile]: {
    width: INTRODUCE_TITLE_WIDTH_MB
  },
  img: {
    width: INTRODUCE_DESCRIPTION_WIDTH_PC,
    [desktopFirstMediaQuery.tablet]: {
      width: INTRODUCE_DESCRIPTION_WIDTH_TB
    },
    [desktopFirstMediaQuery.mobile]: {
      width: INTRODUCE_DESCRIPTION_WIDTH_MB
    }
  }
});

function getProgress({ start, end, current }: { start: number; end: number; current: number }) {
  if (current <= start) {
    return 0;
  }

  if (current >= end) {
    return 1;
  }

  const range = end - start;
  const position = current - start;
  const ratio = position / range;

  return ratio;
}

export default VideoSection;
